










































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import QuestionItem from '@/partials/components/QuestionItem.vue'
import TopicsComboBox from '@/partials/forms/TopicsComboBox.vue'

@Component({
  components: {
    TopicsComboBox,
    QuestionItem
  }
})
export default class ExamsQuestionsSearch extends Vue {
  @Prop() selectedQuestions!: Array<Question>
  questions: Array<Question> = []

  form = {
    topicId: [],
    qty: '5',
    level: '50'
  }

  done = true

  fetchQuestions () {
    this.done = false
    axios.get('exam/question/search', {
      params: snakeCaseKeys({ ...this.form, topicId: this.form.topicId.join(',') })
    })
      .then(response => {
        this.questions = camelCaseKeys(response.data.data, { deep: true })

        const ids = this.selectedQuestions.map(question => question.id)
        this.questions = this.questions.filter(question => !ids.includes(question.id))
      })
      .finally(() => { this.done = true })
  }

  selectAll () {
    this.selectedIds = []
    this.questions.forEach((question: Question) => {
      this.selectedIds.push(question.id)
    })
  }

  selectedIds: Array<string> = []
  submitSelection () {
    const selectedQuestions = this.questions.filter((question: Question) => this.selectedIds.includes(question.id))

    this.$emit('selected', selectedQuestions)
    this.selectedIds = []
    this.questions = []
    this.$emit('done')
  }
}
